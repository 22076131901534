import React from "react"

import { useImage } from "../../hooks/useImage"

export const withContact = Component => ({ name = "Contact", page }) => {
  const { getFluidImage } = useImage()

  const {
    title,
    image: originalImage,
    links,
    faqs,
    contactFormDescription,
    contacts,
    additionalLinksText,
    additionalFaqsText,
    additionalEnquiresText,
    additionalLocationsText,
  } = page

  const image = getFluidImage(originalImage, { maxWidth: 1440 })

  Component.displayName = name
  return (
    <Component
      title={title}
      image={image}
      links={links}
      faqs={faqs}
      contactFormDescription={contactFormDescription}
      contacts={contacts}
      additionalLinksText={additionalLinksText}
      additionalFaqsText={additionalFaqsText}
      additionalEnquiresText={additionalEnquiresText}
      additionalLocationsText={additionalLocationsText}
    />
  )
}
