import React from "react"

import { withForm } from "./withForm"
import { ContactForm, Description, SuccessWrapper, SuccessTitle, SuccessMessage, ErrorMessage } from "./FormStyles"
import { Section, Subtitle, Content } from "../ContactStyles"
import { StyledInput, StyledTextArea, StyledDropdownSelect, StyledSelectOption, StyledSelectWrapper, StyledInputIconWrapper } from "../../Styled/Form"
import { StyledButton } from "../../Styled/Button"
import { Icon } from "../../Icon/Icon"

export const Form = withForm(
  ({
    title,
    description,
    form,
    data,
    handleChange,
    handleSubmit,
    loading,
    errors,
    success,
    additionalSubmitText,
    additionalSubmittingText,
    additionalSuccessTitle,
    additionalSuccessText,
    additionalPlaceholderFirstName,
    additionalPlaceholderLastName,
    additionalPlaceholderEmail,
    additionalPlaceholderType,
    additionalPlaceholderMessage,
  }) => (
    <Section>
      <Subtitle success={success}>{title}</Subtitle>
      <Content>
        {!success ? (
          <ContactForm onSubmit={handleSubmit}>
            <Description>{description}</Description>
            <div className={`flex flex-col md:flex-row`}>
              <StyledInput
                name="firstName"
                type="text"
                value={data?.firstName}
                onChange={handleChange}
                placeholder={additionalPlaceholderFirstName}
                first
                required
                spacing={"true"}
              />
              <StyledInput
                name="lastName"
                type="text"
                value={data?.lastName}
                onChange={handleChange}
                placeholder={additionalPlaceholderLastName}
                last
                required
                spacing={"true"}
              />
            </div>

            <StyledInput
              name="email"
              type="email"
              value={data?.email}
              onChange={handleChange}
              placeholder={additionalPlaceholderEmail}
              required
              spacing={"true"}
            />

            <StyledSelectWrapper spacing={"true"}>
              <StyledDropdownSelect layout={"form"} name="type" value={data?.type} onChange={handleChange}>
                <StyledSelectOption disabled value={""} selected>
                  {additionalPlaceholderType}
                </StyledSelectOption>
                {Object.values(form?.types || {})?.map((type: any) => (
                  <StyledSelectOption key={type?.title?.toString()} value={type?.title}>
                    {type?.title}
                  </StyledSelectOption>
                ))}
              </StyledDropdownSelect>
              <StyledInputIconWrapper>
                <Icon name={"chevron"} className={`mb-0-8 mr-0-8`} width={"12"} height={"12"} />
              </StyledInputIconWrapper>
            </StyledSelectWrapper>

            <StyledTextArea
              name="message"
              value={data?.message}
              onChange={handleChange}
              placeholder={additionalPlaceholderMessage}
              required
              spacing={"true"}
            />

            <StyledButton type={`submit`} disabled={loading} size={"primary"} colour={"black"} wide>
              {loading ? additionalSubmittingText : additionalSubmitText}
            </StyledButton>
            {errors?.length > 0 ? (
              <>
                {errors?.map(error => (
                  <ErrorMessage key={error}>{error}</ErrorMessage>
                ))}
              </>
            ) : null}
          </ContactForm>
        ) : (
          <SuccessWrapper>
            <SuccessTitle>{additionalSuccessTitle}</SuccessTitle>
            <SuccessMessage>{additionalSuccessText}</SuccessMessage>
          </SuccessWrapper>
        )}
      </Content>
    </Section>
  )
)
