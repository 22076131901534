import React from "react"

import { withContact } from "./withContact"
import { Form } from "./Form/Form"
import { Outer, Title, Inner, ImageWrapper } from "./ContactStyles"
import { Image } from "../Image/Image"
import { Links } from "./Links/Links"
import { Faqs } from "./Faqs/Faqs"
import { Details } from "./Details/Details"

export const Contact = withContact(
  ({
    title,
    image,
    links,
    faqs,
    contactFormDescription,
    contacts,
    additionalLinksText,
    additionalFaqsText,
    additionalEnquiresText,
    additionalLocationsText,
  }) => (
    <Outer>
      <Title>{title}</Title>
      <Inner>
        <Links title={additionalLinksText} links={links} />
        <Faqs title={additionalFaqsText} faqs={faqs} />
        <Form title={additionalEnquiresText} description={contactFormDescription} />
        <Details title={additionalLocationsText} contacts={contacts} />
      </Inner>
      <ImageWrapper>
        <Image image={image} />
      </ImageWrapper>
    </Outer>
  )
)
