import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useForm } from "../../../hooks/useForm"

export const withForm = Component => ({ name = "Form", title, description }) => {
  const { contact } = useStaticQuery(graphql`
    query SANITY_PAGE_CONTACT {
      contact: sanityPageContact {
        additionalSubmitText
        additionalSubmittingText
        additionalSuccessTitle
        additionalSuccessText
        additionalPlaceholderFirstName
        additionalPlaceholderLastName
        additionalPlaceholderEmail
        additionalPlaceholderType
        additionalPlaceholderMessage
      }
    }
  `)

  const {
    additionalSubmitText,
    additionalSubmittingText,
    additionalSuccessTitle,
    additionalSuccessText,
    additionalPlaceholderFirstName,
    additionalPlaceholderLastName,
    additionalPlaceholderEmail,
    additionalPlaceholderType,
    additionalPlaceholderMessage,
  } = contact || {}

  const { getForm, submitForm, data, setData, loading, errors, success } = useForm()

  const form = getForm("contact")

  const handleSubmit = async event => {
    event.preventDefault()
    await submitForm(form, data)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  Component.displayName = name
  return (
    <Component
      title={title}
      description={description}
      data={data}
      form={form}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      success={success}
      additionalSubmitText={additionalSubmitText}
      additionalSubmittingText={additionalSubmittingText}
      additionalSuccessTitle={additionalSuccessTitle}
      additionalSuccessText={additionalSuccessText}
      additionalPlaceholderFirstName={additionalPlaceholderFirstName}
      additionalPlaceholderLastName={additionalPlaceholderLastName}
      additionalPlaceholderEmail={additionalPlaceholderEmail}
      additionalPlaceholderType={additionalPlaceholderType}
      additionalPlaceholderMessage={additionalPlaceholderMessage}
    />
  )
}
